export function accordionToggle() {

    const visibleParts = document.querySelectorAll(".accordion__visible-part");
    const userAgent = navigator.userAgent;
    const isMobile = /Mobile/i.test(userAgent);

    function togglePanel() {
        const panel = this.querySelector(".panel");
        const arrow = this.querySelector(".toggle-arrow");

        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            arrow.style.transform = "rotate(0deg)";
            arrow.classList.remove("active");
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            arrow.style.transform = "rotate(180deg)";
            arrow.classList.add("active");
        }
    }

    if (visibleParts) {
        visibleParts.forEach(visiblePart => {
            visiblePart.addEventListener("touchstart", togglePanel);

            if (!isMobile) {
                visiblePart.addEventListener("click", togglePanel);
            }
        });
    }
}